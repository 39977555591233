.website-footer{
    .footer-content{
        padding: 94px 24px;
        background: #14141B;
        .content-center{
            max-width: 1200px;
            width: 100%;
        }
        .footer-content-left{
            .email-input{
                position: relative;
                background: rgba(120, 99, 251, 0.1);
                border: 1px solid #7063ED;
                border-radius: 50px 0 0 50px;
                border-right: 0;
                max-width: 400px;
                height: 45px;
                input{
                    color: #fff;

                }
                .submit-button{
                    position: absolute;
                    right: -20px;
                    width: 136px;
                    height: 45px;
                    border-radius: 50px;
                    background: #7863FB;
                    .submit-status-btn{
                        flex-shrink: 0;
                        position: relative;
                        overflow: hidden;
                        margin-right: 12px;
                        width: 16px;
                        height: 16px;
                        border-radius: 8px;
                        background: #fff;
                        display: flex;
                        justify-content: center;
                        .iconfont{
                            position: absolute;
                            font-size: 12px;
                            color: #7863FB;
                            transition: all 0.2s;
                            &.icon-icon-arrow2{
                                top: 20px;
                                transform: rotate(180deg);
                                &.active{
                                    top: -3px;
                                }
                            }
                            &.icon-icon-yep{
                                top: -15px;
                                &.active{
                                    top: 1px;
                                }

                            }
                        }
                    }
                }
            }
            .footer-links{
                gap:32px;
                .footer-link{
                    color: #3E3F45;   
                    transition: color .2s;   
                    font-size: 32px;              
                    &:hover{
                        color: #7863FB;
                    }
                }   
                .contact{
                    &:hover{
                        color: #7863FB;
                    }
                }
            }
        }
        .footer-content-right{
            // margin-left: 260px;
            height: 178px;
            gap:54px;
            .footer-link{
                >div:nth-child(1){
                    margin-bottom: 32px;
                }
            }
        }
    }
    .footer-bottom{
        @media screen and(max-width:1024px){
            height: 84px;
        }

        height: 69px;
        padding: 0 20px;
        background: #14141B;
        .bottom-text{
            @media screen and(max-width:1024px){
                // height: 84px;
                display: none;
            }
    
            width: 1200px;
            .point{
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: #7863FB;
                margin-right: 4px;
            }
        }
        .mb-footer-links{
            display: none;
            @media screen and(max-width:1024px){
                display: flex;
            }
    
            gap: 32px;
            .mb-footer-link{
                font-size: 24px;
                color: #7863FB;
            }
        }
    }
}

@media (max-width: 768px) {
 .website-footer{
    .footer-content{
        padding: 24px;
        >div{
            flex-direction: column;
            gap: 20px;
            .footer-content-left{
                width: 95%;
                display: flex;
                flex-direction: column;
                align-items: center;
                .email-input{
                    width: 95%;
                    .submit-button{
                        width: 100px;
                    }
                }
                .footer-links{
                    gap: 24px;
                    .footer-link{
                        font-size: 20px;
                    }
                }
                .sub_title{
                    text-align: center;
                }
            }
        }
        .footer-content-right{
            height: auto;
            width: 100%;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 24px;
            @media (max-width:400px) {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }
            .footer-link{
                text-align: center;
                >div:nth-child(1){
                    margin-bottom: 12px;
                    white-space: nowrap;
                }
            }
        }
    }
 }   
}
html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: Poppins;
  src: url('../../public/font/Poppins-Regular.otf');
  font-weight: 400;
}

@font-face {
  font-family: Poppins;
  src: url('../../public/font/Poppins-SemiBold.otf');
  font-weight: 600;
}

@font-face {
  font-family: Poppins;
  src: url('../../public/font/Poppins-Medium.otf');
  font-weight: 500;
}


@font-face {
  font-family: Poppins;
  src: url('../../public/font/Poppins-Bold.otf');
  font-weight: 700;
}



* {
  // scrollbar-color: transparent;
  // scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background:transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
}

body {
    margin: 0;
    font-size: 14px;
    line-height: 1.5;
    font-family: 'Poppins', PingFangSC-Regular, 'Microsoft YaHei', sans-serif;
    height: 100%;
    width: 100vw;
    color: #171717;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-tap-highlight-color: transparent;

}
iframe {
    visibility: hidden;
    pointer-events: none;
}
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.page {
  flex: 1;
}
div{
    box-sizing: border-box;
}
p{
    margin: 0;
}

a {
    color: inherit;
    text-decoration: none;
  }

img {
  user-select: none;
  -webkit-user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-main {
  background: #7863FB;
}
.bg-disabled {
  background: #8B8B90;
}
.text-main{
    color: #7063ED;
}
.text-1{
    color: #8B8B90
}
.text-2{
    color: #FAFAFA;
}
.text-3{
    color: #171717;
}
.text-white{
    color: #fff;
}

.clearfix::after {
  display: block;
  content: "";
  clear: both;
}

.news-detail-html {
  word-break: break-all;
}

.news-detail-html p {
  margin-bottom: 0 !important;

}
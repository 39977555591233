.top-content{
    // height: 100vh;
    // padding: 128px 0 198px 0;
    position: relative;
    background-color: #14141B;
    @media screen and(max-width:1024px) {
        height: 100vh;
        // padding: 46px 0;
    }
    .bg-video{
        width: 100vw;
        @media screen and(max-width:1024px) {
            height: 100vh;
            width: auto;
        }
    }

    .content-center{
        // height: 555px;
        max-width: 1200px;
        position: absolute;
        bottom: 0;
        top: 0;
        @media screen and(max-width:1024px) {
            flex-direction: column;
            align-items: center;
            height: 100%;
            padding: 0 24px;
        }
    .content-left{
            @media screen and(max-width:1024px) {
                order: 2;
                .content-title{
                    margin-top: 22px;
                    text-align: center;
                    font-size: 24px!important;
                    .sub-title{
                        margin-top: 0!important;
                    }
                }
    
            }
            .content-title{
                font-size: 40px;
                .sub-title{
                    margin-top: -12px;
                }
            }
            .content-desc{
                padding: 24px 0;
                // width: 700px;
                font-size: 24px;
                @media screen and(max-width:1024px) {
                    width: 100%;
                    padding: 12px 0;
                    text-align: center;
                }
            }
            .jump-btn{
                gap: 12px;
                height: 48px;
                position: relative;
                @media screen and(max-width:1024px) {
                    justify-content: center;
                }
                @media (max-width:768px) {
                    flex-direction: column;
                    height: auto;
                }

                .launch-app{
                    // position: absolute;
                    padding: 12px 24px;
                    border-radius: 500px;
                    font-size: 16px;
                    transition: all 0.2s;
                    white-space: nowrap;
                    width: 196px;
                    &:hover{
                        margin-top: -5px;
                    }
                    @media screen and(max-width:1024px) {
                        &:first-child{
                            // right: 50%
                        }
                    }
    
                    &:last-child{
                        // left:168px;
                        @media screen and(max-width:1024px) {
                            // left: 55%;
                        }
    
                    }
                }
            }
            .content-links{
                @media screen and(max-width:1024px) {
                    justify-content: center;
                }

                margin-top: 24px;
                gap:24px;
                .content-link{
                    font-size: 22px;
                    color: #fff;
                    transition: color 0.2s ease-in-out;
                    &:hover{
                        color: #7863FB;
                    }
                }
                .contact{
                    &:hover{
                        color: #7863FB;
                    }

                }
            }
        }
        .content-right{
            @media screen and(max-width:1024px) {
                order: 1;   
                width: 500px; 
                height: 500px;
                margin: -120px;
                overflow: hidden;
                .canvas3d{
                    transform: scale(0.57);
                    border-radius: 50%;
                    overflow: hidden;
                }
            }
            flex-shrink: 0;
            width: 531px;
            border-radius: 265px;
            overflow: hidden;
            background: #27282F;
            .spline-watermark{
                display: none;
            }
        }
    }
    .scroll-target{
        @media screen and(max-width:1024px) {
            display: none;
        }
        width: 1231px;
        .swiper-down{
            width: 17px;
            height: 24px;
            border: 2px solid #8B8B91;
            margin-right: 16px;
            border-radius: 16px;
            position: relative;
            @keyframes identifier {
                0%{
                    transform: translateY(4px);
                }
                50%{
                    transform: translateY(12px);
                }
                60%{
                    opacity: 0.02;
                }
                80%{
                    opacity: 1;
                }
                100%{
                    transform: translateY(4px);
                }
            }
            .swiper-line{
                position: absolute;
                width: 1.2px;
                height: 4px;
                background: #8B8B91;
                animation: identifier 1.5s linear infinite;
            }
        }
    }
}
.official-top{
    background: inherit;
    backdrop-filter: blur(100px);
    height: 80px;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 99;
    @media screen and (max-width:1024px) {
        height: 63px;
        z-index: 1001;
    }

    .top-nav{
        width: 1920px;
        height: 100%;
        padding: 0 24px;
        .theme-logo{
            position: relative;
            width: 120px;
            // height: 30px;
            .iconfont{
                position: absolute;
                top: -18px;
                font-size: 25px;
            }
        }
        .nav-links{
            @media screen and (max-width:1024px) {
                display: none;
            }
            height: 100%;
            margin-left: 64px;
            gap: 24px;
            display: grid;
            grid-template-columns: 61px 110px 142px 107px 85px 122px 122px;
            .nav-link{
                height: 100%;
                &:hover{
                    .link-tit{
                        color: white;
                        font-weight: 500;
                    }
                    .icon-icon-arrow2{
                        transform: rotate(180deg);
                        color: white;
                    }
                    .link-child-navs{
                        display: flex;
                        // opacity: 1;
                        top:80px;
    
                    }
                }
                .link-tit{
                    transition: all 0.3s;
                    white-space: nowrap;
                }
                .icon-icon-arrow2{
                    transition: all 0.3s;
                    font-size: 14px;
                }
                .link-child-navs{
                    @media screen and (max-width:1024px) {
                        display: none;
                    }
        
                    z-index: 10;
                    // opacity: 0;
                    display: none;
                    top: 75px;
                    width: 110px;
                    &.Developer{
                        transform: translateX(-10%);
                    }
                    background: #14141B;
                    position: absolute;
                    transition: all 0.25s ease-in-out;
                    .link-child-nav{
                        border-bottom: 1px solid #FFFFFF1A;
                        &:last-child{
                            border-bottom: 0px solid #FFFFFF1A;
                        }
                        
                    }
                }

            }
        }
        .right-btn{
            @media screen and (max-width:1024px) {
                display: none;
            }

            padding: 12px 24px;
            border-radius: 500px;
            transition: all 0.2s;
            font-size: 16px;
            &:hover{
                margin-top: -5px;
            }
        }
        .icon-icon-menu{
            @media screen and (max-width:1024px) {
                display: block;
            }
            font-size: 26px;
            display: none;
        }
    }

}
.ant-drawer-content-wrapper{
    top: 87px!important;
    display: flex;
    justify-content: center;
    // height: 100%;
    .mobile-navs-drawer{
        width: calc(100% - 48px)!important;
        background: #14141B;
        border-radius: 24px;
        .ant-drawer-header{
            display: none!important;
        }
        .ant-drawer-body{
            padding: 0 24px;
        }
        .mobile-nav{
            padding: 24px 12px;
            font-size: 14px;
            font-weight: 500;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);        
            &:last-child{
                border: 0;
            }
            .icon-icon-arrow2{
                transition: all .3s;
                &.active{
                    transform: rotate(180deg);
                }
            }
            .mobile-nav-childs{
                overflow: hidden;
                transition: all 0.2s;
                gap: 8px;
                &.notExpand{
                    height: 0;
                }
            }
        }
    }
    
}
.ant-drawer-content-wrapper{
    box-shadow: none!important;
}
.ant-drawer-mask{
    background: rgba(34, 35, 41, 0.8)!important;
    // backdrop-filter: blur(1px)!important;
}
.email-modal{
    &.airdrop_modal{
        .ant-modal-content{
            padding: 12px;
        }
        .ant-modal-close-x{
            display: block;
        }
    }
   
    .list{
        list-style-type: none; 
    }
    .list{
        li{
            counter-increment: list-counter;
            position: relative;
        }
    }
    .list{
        li::before {
            content: counter(list-counter) ". ";
            position: absolute;
            left: -1em;
        }
    }
}

.mobile-menu {
  transform: translateX(-100%);
  transition: transform .2s ease;

  &.slide-open {
    transform: translateX(0);
  }
}